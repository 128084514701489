
$(function() {
    // limits the number of service protocols
    $('#service_profile_protocols').on('cocoon:after-insert', function() {
        check_to_hide_or_show_add_link();
    });

    $('#service_profile_protocols').on('cocoon:after-remove', function() {
        check_to_hide_or_show_add_link();
    });

    check_to_hide_or_show_add_link();

    function check_to_hide_or_show_add_link() {
        if ($('.nested-fields').length === 4) {
            $('#add-service-protocol').hide();
        } else {
            $('#add-service-protocol').show();
        }
    }


    // limits the number of service protocol rules
    $('#service_profile_protocol_rules').on('cocoon:after-insert', function() {
        check_to_hide_or_show_add_link_rule();
    });

    $('#service_profile_protocol_rules').on('cocoon:after-remove', function() {
        check_to_hide_or_show_add_link_rule();
    });

    check_to_hide_or_show_add_link_rule();

    function check_to_hide_or_show_add_link_rule() {
        if ($('.nested-fields').length === 10) {
            $('#add-service-protocol-rule').hide();
        } else {
            $('#add-service-protocol-rule').show();
        }
    }
});
